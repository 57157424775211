import { addPropertyControls, ControlType } from "framer"

import * as m from "https://ruucm.github.io/shadergradient/shadergradient@2.0.22/FramerShaderGradient.mjs"

/**
 * @framerIntrinsicHeight 700
 * @framerIntrinsicWidth 500
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export default function ShaderGradient(props) {
    return (
        <m.ShaderGradientCanvas pointerEvents={props.pointerEvents}>
            <m.FramerShaderGradient {...props} />
        </m.ShaderGradientCanvas>
    )
}

addPropertyControls(
    ShaderGradient,
    m.FramerShaderGradient.propertyControls || {}
)
